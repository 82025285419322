import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Container,
    Form,
    Image,
    Nav,
    Navbar,
    NavDropdown,
} from 'react-bootstrap';
import { getCookie } from 'api';
import { gettext } from '../../utils/text';
import reverseUrl from '../../utils/urls';
import styles from './NavigationBar.scss';

import ThinFacilityLogo from '../../assets/img/thinfacility_logo.png';

import GlobeIcon from '../../assets/icons/globe-icon.svg';
import EEIcon from '../../assets/icons/flags/ee.svg';
import GBIcon from '../../assets/icons/flags/gb.svg';
import ListIcon from '../../assets/icons/list-icon.svg';
import ThreeDotsIcon from '../../assets/icons/three-dots-vertical.svg';

import {
    selectNavBarOpen,
    selectSideBarMinimized,
    selectSideBarOpen,
    setNavBar,
    setSideBar,
    setSideBarMinimized,
} from '../../ducks/global';
import { useWindowSize } from '../../utils/hooks';

const transLogOut = gettext('Log out');
const transLogIn = gettext('Log in');

const NavigationBar = () => {
    const dispatch = useDispatch();

    const { width } = useWindowSize();

    const sideBarStatus = useSelector(selectSideBarOpen);
    const sideBarMinimizedStatus = useSelector(selectSideBarMinimized);
    const navBarStatus = useSelector(selectNavBarOpen);

    useEffect(() => {
        if (sideBarStatus) {
            document
                .getElementsByTagName('main')[0]
                .classList.add('main-side-open');
            document.getElementById('main-body').classList.add('side-open');
        } else {
            document
                .getElementsByTagName('main')[0]
                .classList.remove('main-side-open');
            document.getElementById('main-body').classList.remove('side-open');
        }

        if (sideBarMinimizedStatus && !sideBarStatus) {
            document
                .getElementById('main-body')
                .classList.add('side-minimized');
        } else {
            document
                .getElementById('main-body')
                .classList.remove('side-minimized');
        }

        if (navBarStatus) {
            document.getElementById('main-body').classList.add('top-open');
        } else {
            document.getElementById('main-body').classList.remove('top-open');
        }

        if (navBarStatus && sideBarStatus) {
            document.getElementById('main-body').classList.add('both-open');
        } else {
            document.getElementById('main-body').classList.remove('both-open');
        }
    }, [sideBarStatus, navBarStatus, sideBarMinimizedStatus]);

    useEffect(() => {
        if (width > 991) {
            dispatch(setSideBar(false));
            dispatch(setNavBar(false));
        } else {
            dispatch(setSideBarMinimized(false));
        }
    }, [dispatch, width]);

    function logout() {
        sessionStorage.clear();
        window.location.replace(
            `${window.location.origin}${reverseUrl('logout')}`,
        );
    }

    function renderItemsRight() {
        return (
            <>
                {DJ_CONST.user ? (
                    <>
                        <NavDropdown
                            title={
                                <Button
                                    variant="outline-primary"
                                    className="border-0 text-white"
                                >
                                    <GlobeIcon />
                                </Button>
                            }
                            className={styles.langDropdown}
                            id="navbarScrollingDropdown"
                        >
                            <Form
                                action={reverseUrl('set_language')}
                                method="POST"
                            >
                                <input
                                    name="language"
                                    type="hidden"
                                    value="et"
                                />
                                <input
                                    name="csrfmiddlewaretoken"
                                    type="hidden"
                                    value={getCookie('csrftoken')}
                                />
                                <NavDropdown.Item
                                    as={Button}
                                    className="d-flex align-items-center"
                                    type="submit"
                                >
                                    <EEIcon />
                                    <span className="ml-2">Eesti</span>
                                </NavDropdown.Item>
                            </Form>
                            <Form
                                action={reverseUrl('set_language')}
                                method="POST"
                            >
                                <input
                                    name="language"
                                    type="hidden"
                                    value="en"
                                />
                                <input
                                    name="csrfmiddlewaretoken"
                                    type="hidden"
                                    value={getCookie('csrftoken')}
                                />
                                <NavDropdown.Item
                                    as={Button}
                                    className="d-flex align-items-center"
                                    type="submit"
                                >
                                    <GBIcon />
                                    <span className="ml-2">English</span>
                                </NavDropdown.Item>
                            </Form>
                        </NavDropdown>
                        <Nav.Link disabled>
                            {DJ_CONST.user.name || DJ_CONST.user.email}
                        </Nav.Link>
                        <Nav.Link onClick={() => logout()}>
                            {transLogOut}
                        </Nav.Link>
                    </>
                ) : null}
                {!DJ_CONST.user ? (
                    <Nav.Link href={reverseUrl('login')}>{transLogIn}</Nav.Link>
                ) : null}
            </>
        );
    }

    return (
        <nav
            className={`${styles.mainHeader} ${
                sideBarStatus ? styles.sidebarOpen : ''
            }`}
        >
            <div
                className={`${styles.sideBarHeader} ${
                    sideBarMinimizedStatus && !sideBarStatus
                        ? styles.sidebarMinimized
                        : ''
                }`}
            >
                <span className={styles.logo}>
                    <Image
                        src={ThinFacilityLogo}
                        alt="ThinFacility logo"
                        fluid
                    />
                </span>
                <Button
                    className={styles.navbarToggler}
                    onClick={
                        width > 991
                            ? () => {
                                  dispatch(
                                      setSideBarMinimized(
                                          !sideBarMinimizedStatus,
                                      ),
                                  );
                              }
                            : () => {
                                  dispatch(setSideBar(!sideBarStatus));
                              }
                    }
                >
                    <ListIcon className={styles.togglerIcon} />
                </Button>
                <Button
                    className={styles.sidebarToggler}
                    onClick={() => {
                        dispatch(setNavBar(!navBarStatus));
                    }}
                >
                    <ThreeDotsIcon className={styles.togglerIcon} />
                </Button>
            </div>
            <Navbar
                bg="dark"
                variant="dark"
                className={`${styles.navbarHeader} ${
                    navBarStatus ? styles.navbarOpen : ''
                }`}
            >
                <Container fluid>
                    <Nav className="ml-auto mr-auto mr-lg-0 align-items-center">
                        {renderItemsRight()}
                    </Nav>
                </Container>
            </Navbar>
        </nav>
    );
};

export default NavigationBar;
